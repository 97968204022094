import validateProject from "@/middleware/validateProject";
import loadProjectTypes from "@/middleware/loadProjectTypes";
import loadProjectStatuses from "@/middleware/loadProjectStatuses";

export default {
  name: "ProjectOverview",
  path: ":id/overview",
  component: () => import("@/views/ProjectOverview"),
  props: true,
  meta: {
    middleware: [validateProject, loadProjectTypes, loadProjectStatuses]
  }
};
