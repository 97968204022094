import Vue from "vue";
import Vuex from "vuex";
import config from "@/config";
import auth from "./modules/auth";
import providers from "./modules/providers";
import registration from "./modules/registration";
import projects from "./modules/projects";
import createProject from "./modules/createProject";
import archived from "./modules/archived";
import projectOverview from "./modules/projectOverview";
import service from "./modules/service";
import ahjTable from "./modules/ahjTable";
import transactions from "./modules/transactions";
import clients from "./modules/clients";
import vendors from "./modules/vendors";
import groups from "./modules/groups";
import user from "./modules/user";
import users from "./modules/users";
import location from "./modules/location";
import stamping from "./modules/stamping";
import notifications from "./modules/notifications";
import profile from "./modules/profile";
import projectImporter from "./modules/projectImporter";
import stamps from "./modules/stamps";
import stampingProfiles from "./modules/stampingProfiles";
import stampingProfile from "./modules/stampingProfile";
import iebcStamping from "./modules/iebcStamping";
import _ from "lodash";
import api from "@/api";
import cookies from "js-cookie";
import codesAndConditionsTable from "./modules/codesAndConditionsTable";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    config,
    navbar_color: "alabaster",
    sidebar_color: "biscay",
    horizontal_layout: false,
    sidebar_drawer: true,
    is_redirecting: false,
    account_balance: 0.0,
    pzse_email: "portal@pzse.com",
    patch_notes_dialog: false,
    patch_notes: [],
    status_tour: "ready", // ongoing, done
    banner: true
  },
  getters: {},
  mutations: {
    SET_NAVBAR_COLOR: (state, payload) => (state.navbar_color = payload),
    SET_SIDEBAR_COLOR: (state, payload) => (state.sidebar_color = payload),
    SET_HORIZONTAL_LAYOUT: (state, payload) =>
      (state.horizontal_layout = payload),
    SET_SIDEBAR_DRAWER: (state, payload) => (state.sidebar_drawer = payload),
    SET_IS_REDIRECTING: (state, payload) => (state.is_redirecting = payload),
    SET_ACCOUNT_BALANCE: (state, payload) => (state.account_balance = payload),
    SET_BANNER: (state, payload) => (state.banner = payload),
    SET_PATCH_NOTES_LINK: (state, payload) => {
      if (payload) {
        state.banner = true;
        state.patch_notes_url = payload;
      }
    },
    SET_PATCH_NOTES_DIALOG: (state, payload) => {
      if (payload) {
        state.patch_notes_dialog = payload;

        cookies.set("patch", true);

        return;
      }

      state.patch_notes_dialog = false;
    },
    SET_PATCH_NOTES: (state, payload) => (state.patch_notes = payload),
    SET_STATUS_TOUR: (state, payload) => {
      cookies.set("tour_dialog", payload);

      state.status_tour = payload;
    }
  },
  actions: {
    getAccountBalance: async ({ commit }) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get(`/account-balances`);

        const account_balance = _.get(response, "data.result", {});

        const formatted_account_balance = parseFloat(
          account_balance.amount
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

        commit("SET_ACCOUNT_BALANCE", formatted_account_balance);

        return response;
      } catch (error) {
        throw error;
      }
    }
  },
  modules: {
    auth,
    providers,
    registration,
    projects,
    createProject,
    archived,
    projectOverview,
    service,
    transactions,
    ahjTable,
    clients,
    groups,
    user,
    location,
    users,
    stamping,
    notifications,
    profile,
    projectImporter,
    stamps,
    stampingProfiles,
    stampingProfile,
    iebcStamping,
    vendors,
    codesAndConditionsTable
  }
});
