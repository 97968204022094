import _ from "lodash";
import api from "@/api";
import filter from "./filter";

export default {
  namespaced: true,
  state: {
    list: [],
    meta: {},
    vendor: {
      company_name: "",
      owner: {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: ""
      },
      address: {
        address_1: "",
        address_2: "",
        postal_code: "",
        city: "",
        state: ""
      }
    },
    show_suspended: false,
    term: "",
    filters: {
      type: "",
      owner_id: "",
      billing_contact_id: "",
      point_of_contact_id: "",
      address: "",
      address_id: "",
      member_since_from: "",
      member_since_to: ""
    },
    filter_applied: false,
    selected_states: [],
    selected_company_exceptions: []
  },
  getters: {
    is_filter_active: state => {
      return Object.values(state.filters).some(value => {
        return value !== "";
      });
    }
  },
  mutations: {
    SET_SEARCH_TEXT: (state, payload) => {
      state.term = payload;
    },
    SET_FILTERS: (state, payload) => {
      state.filters = payload;
    },
    SET_VENDORS_DATA: (state, payload) => {
      state.list = payload.data;
      state.meta = payload.meta;
    },
    SET_VENDOR: (state, payload) => (state.vendor = payload),
    SET_SELECTED_STATES: (state, payload) => (state.selected_states = payload),
    SET_SELECTED_COMPANY_EXCEPTIONS: (state, payload) =>
      (state.selected_company_exceptions = payload),
    SET_SHOW_SUSPENDED: (state, payload) => {
      state.show_suspended = payload;
    },
    CLEAR_FILTERS_SEARCH: state => {
      state.term = "";
      state.filters = {
        type: "",
        owner_id: "",
        billing_contact_id: "",
        point_of_contact_id: "",
        address_id: "",
        member_since_from: "",
        member_since_to: ""
      };
    }
  },
  actions: {
    getVendors: async ({ state, commit, getters }, page_number) => {
      // this could be fix in BE to just set show_archived as bool
      let show_archived = state.show_suspended ? 1 : "";

      const data = await api.get("/accounts", {
        params: {
          page: page_number,
          per_page: 10,
          show_archived: show_archived,
          term: state.term,
          classification: "vendor-electrical",
          ...state.filters
        }
      });

      state.filter_applied = getters.is_filter_active;

      const result = _.get(data, "data.result", []);

      state.selected_states = [];
      state.selected_company_exceptions = [];

      _.forEach(result.data, account => {
        _.forEach(account.electrical_states, electrical_state => {
          state.selected_states.push(electrical_state);
        });

        _.forEach(account.electrical_company_exceptions, company => {
          state.selected_company_exceptions.push(company);
        });
      });

      commit("SET_VENDORS_DATA", result);
    }
  },
  modules: {
    filter
  }
};
