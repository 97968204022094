import _ from "lodash";
import * as projectTypes from "@/helpers/projectTypes";
import {
  wetStampOneToFour,
  wetStampFiveToEight,
  pzseExpress,
  pzseExpressRestamp
} from "@/helpers/projectAddons";
const initialProjectMetaState = () => ({
  building_width: null,
  building_length: null,
  number_of_stories: null,
  number_of_panels: null,
  roof_type_actual: null,
  full_calcs: {
    array: []
  },
  roof_type: null,
  roof_slope: null,
  type_pitch: null
});
const initialState = () => ({
  fixed_selected_types: [],
  project_types: [],
  project_addons: [],
  shipping_profile_id: null,
  shipping_profiles: [],
  formdata: {
    project_types: [],
    project_addons: [],
    wet_stamps: 0,
    shipping_profile: {},
    set_default_shipping_profile: false,
    project_meta: initialProjectMetaState(),
    name: null,
    client_po: null,
    options: []
  },
  sp_file: null,
  ep_file: null,
  project_costs: {
    current_balance: 0,
    project_addons_cost: [],
    project_types_cost: [],
    remaining_balance: 0,
    total_cost: 0,
    total_project_addons_cost: 0,
    total_project_types_cost: 0
  },
  data_fetching: false,
  has_upload_structural_plans: null,
  project_documents_structural_plans: {},
  has_upload_electrical_plans: null,
  sp_queue_completed: false,
  calculating: false,
  calculation_delay_in_sec: 1.5,
  pzse_express_agree: false
});

export default {
  namespaced: true,
  state: initialState,
  getters: {
    wet_stamp_price: state => {
      const count = state.formdata.wet_stamps;
      const wetstamp1to4 = wetStampOneToFour();
      const wetstamp5to8 = wetStampFiveToEight();

      if (_.inRange(count, 5, 9)) {
        return state.project_addons.find(addon => addon.id === wetstamp5to8.id);
      }

      return state.project_addons.find(addon => addon.id === wetstamp1to4.id);
    },
    is_wet_stamp_project_addon_selected: state => {
      const wetstamp1to4 = wetStampOneToFour();
      const wetstamp5to8 = wetStampFiveToEight();

      return !!state.formdata.project_addons.find(
        addon => addon.id === wetstamp1to4.id || addon.id === wetstamp5to8.id
      );
    },
    is_pzse_express_selected: state => {
      const pzseExpressVar = pzseExpress();

      return !!state.formdata.project_addons.find(
        addon => addon.id === pzseExpressVar.id
      );
    },
    is_pzse_express_addons_selected: state => {
      const pzseExpressVar = pzseExpress();
      const pzseExpressRestampVar = pzseExpressRestamp();

      return !!state.formdata.project_addons.find(
        addon =>
          addon.id === pzseExpressVar.id ||
          addon.id === pzseExpressRestampVar.id
      );
    },
    is_project_fs_selected_only: state => {
      const project_types = state.formdata.project_types;
      const projectType = projectTypes.fullStructural();

      if (projectType) {
        return (
          project_types.length == 1 &&
          !!project_types.find(type => type.id === projectType.value)
        );
      }

      return false;
    },
    is_iebc_letter: state => {
      const project_types = _.get(state, "formdata.project_types", []);
      const projectType = projectTypes.iebcLetter();
      const projectTypeAdd = projectTypes.iebcLetterWithStampedPlans();

      if (projectType || projectTypeAdd) {
        return (
          project_types.length >= 1 &&
          (!!_.find(project_types, { id: projectType.value }) ||
            !!_.find(project_types, { id: projectTypeAdd.value }))
        );
      }

      return false;
    },
    is_full_structural: state => {
      const project_types = _.get(state, "formdata.project_types", []);
      const projectType = projectTypes.fullStructural();

      if (projectType) {
        return (
          project_types.length >= 1 &&
          !!_.find(project_types, { id: projectType.value })
        );
      }

      return false;
    },
    is_electrical_stamp: state => {
      const project_types = _.get(state, "formdata.project_types", []);
      const projectType = projectTypes.electricalStamp();

      if (projectType) {
        return (
          project_types.length >= 1 &&
          !!_.find(project_types, { id: projectType.value })
        );
      }

      return false;
    }
  },
  mutations: {
    INITIALIZE_UPGRADE: (state, selected_project_types) => {
      let array = [];

      selected_project_types.forEach(item => {
        array.push(state.project_types.find(type => type.id === item));
      });

      state.formdata.project_types = _.cloneDeep(array);
      state.fixed_selected_types = _.cloneDeep(array);
    },

    SET_PROJECT_TYPES: (state, { project_types }) =>
      (state.project_types = project_types),

    SET_FORMDATA_PROJECT_TYPES: (state, { project_types }) =>
      (state.formdata.project_types = _.orderBy(
        project_types,
        ["name"],
        ["asc"]
      )),

    SET_FORMDATA_PROJECT_ADDONS: (state, { project_addons }) =>
      (state.formdata.project_addons = _.orderBy(
        project_addons,
        ["name"],
        ["asc"]
      )),

    RESET_FORMDATA_PROJECT_ADDONS: state =>
      (state.formdata.project_addons = []),

    SET_FORMDATA_SET_DEFAULT_SHIPPING_PROFILE: (
      state,
      { set_default_shipping_profile }
    ) =>
      (state.formdata.set_default_shipping_profile = set_default_shipping_profile),

    SET_SHIPPING_PROFILE_ID: (state, payload) =>
      (state.shipping_profile_id = payload),

    SET_FORMDATA_SHIPPING_PROFILE: (state, payload) =>
      (state.formdata.shipping_profile = payload),

    SET_SHIPPING_PROFILES: (state, payload) =>
      (state.shipping_profiles = payload),

    SET_FORMDATA_WET_STAMPS: (state, { wet_stamps }) =>
      (state.formdata.wet_stamps = wet_stamps),

    SET_PROJECT_ADDONS: (state, { project_addons }) =>
      (state.project_addons = project_addons),

    SET_PROJECT_COSTS: (state, { costs }) => (state.project_costs = costs),

    SET_INITIAL_STATE: state => Object.assign(state, initialState()),

    SET_INITIAL_STATE_PROJECT_META: state =>
      Object.assign(state.formdata.project_meta, initialProjectMetaState()),

    SET_DATA_FETCHING: (state, payload) => (state.data_fetching = payload),

    SET_FORMDATA_CLIENT_PO: (state, payload) =>
      (state.formdata.client_po = payload),

    SET_FORMDATA_NAME: (state, payload) => (state.formdata.name = payload),

    SET_FORMDATA_ROOF_TYPE_ACTUAL: (state, payload) =>
      (state.formdata.project_meta.roof_type_actual = payload),

    SET_FORMDATA_BUILDING_WIDTH: (state, payload) =>
      (state.formdata.project_meta.building_width = payload),

    SET_FORMDATA_BUILDING_LENGTH: (state, payload) =>
      (state.formdata.project_meta.building_length = payload),

    SET_FORMDATA_NUMBER_OF_STORIES: (state, payload) =>
      (state.formdata.project_meta.number_of_stories = payload),

    SET_FORMDATA_NUMBER_OF_PANELS: (state, payload) =>
      (state.formdata.project_meta.number_of_panels = payload),

    SET_FORMDATA_ROOF_TYPE: (state, payload) =>
      (state.formdata.project_meta.roof_type = payload),

    SET_FORMDATA_TYPE_PITCH: (state, payload) =>
      (state.formdata.project_meta.type_pitch = payload),

    SET_FORMDATA_ROOF_SLOPE: (state, payload) =>
      (state.formdata.project_meta.roof_slope = payload),

    SET_FORMDATA_FULL_CALCS: (state, payload) =>
      (state.formdata.project_meta.full_calcs = payload),

    SET_FORMDATA_PROJECT_META: (state, payload) =>
      (state.formdata.project_meta = payload),

    SET_PROJECT_DOCUMENTS_SP: (state, payload) =>
      (state.project_documents_structural_plans = payload),

    SET_HAS_UPLOAD_ELECTRICAL_PLANS: (state, payload) =>
      (state.has_upload_electrical_plans = payload),

    SET_SP_QUEUE_COMPLETED: (state, payload) =>
      (state.sp_queue_completed = payload),

    SET_SP_FILE: (state, payload) => (state.sp_file = payload),

    SET_EP_FILE: (state, payload) => (state.ep_file = payload),

    SET_CALCULATING: (state, payload) => (state.calculating = payload),

    SET_PZSE_EXPRESS_AGREE: (state, payload) =>
      (state.pzse_express_agree = payload),

    SET_FORMDATA_SURROUNDING_TERRAIN: (state, payload) =>
      (state.formdata.project_meta.surrounding_terrain = payload)
  },
  actions: {}
};
