import _ from "lodash";
import api from "@/api";
import * as projectTypes from "@/helpers/projectTypes";

export default {
  namespaced: true,
  state: {
    project: {},
    is_within_time_delay: false,
    project_created_at: null,
    max_wet_stamp_per_project: 8,
    is_structural_plan_stamped: false,
    iebc_project_types: [3, 4, 21, 22, 23, 24, 25, 26],
    states: [],
    recently_uploaded_files: []
  },
  getters: {
    upgradable: (state, getters) => {
      return !getters.is_project_type_max;
    },
    is_project_type_max: state => {
      /**
       * Note: Project Type is MAX if
       * project type lengths === 3 AND
       * there is Full Structural in the mix
       */

      const FS = projectTypes.fullStructural();
      const has_fs = state.project.project_types.includes(FS.value);

      return state.project.project_types.length === 3 && has_fs;
    },
    is_project_iebc: state => {
      const project_types = _.get(state, "project.project_types", []);
      const projectType = projectTypes.iebcLetter();
      const projectTypeAdd = projectTypes.iebcLetterWithStampedPlans();

      if (projectType || projectTypeAdd) {
        return (
          !!project_types.find(id => id === projectType.value) ||
          !!project_types.find(id => id === projectTypeAdd.value)
        );
      }

      return false;
    },
    is_project_iebc_only: state => {
      const project_types = _.get(state, "project.project_types", []);
      const projectType = projectTypes.iebcLetter();

      if (projectType) {
        return (
          project_types.length == 1 &&
          !!project_types.find(id => id === projectType.value)
        );
      }

      return false;
    },
    is_available_wet_stamp_count: state => {
      const request_list = _.get(
        state,
        "project.project_wetstamp_requests",
        []
      );
      let count = 0;

      if (request_list.length > 0) {
        count = request_list.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.wet_stamps;
        }, 0);
      }

      return state.max_wet_stamp_per_project - count;
    },
    is_post_install_letter_required: state => {
      if (state.project) {
        return state.project.project_documents.some(
          item => item.project_document_type.key === "POST_INSTALL_PHOTOS"
        );
      }

      return false;
    },
    is_project_full_calcs_express: state => {
      const full_calcs = _.get(state, "project.project_meta.full_calcs", []);

      return !!full_calcs;
    },
    is_project_express: state => {
      const is_express = _.get(state, "project.is_express", []);

      return is_express;
    },

    is_electrical_stamp: state => {
      const project_types = _.get(state, "project.project_types", []);
      const projectType = projectTypes.electricalStamp();

      if (projectType) {
        return (
          project_types.length >= 1 &&
          !!_.find(project_types, item => item === projectType.value)
        );
      }

      return false;
    },

    is_electrical_fault_study: state => {
      const project_types = _.get(state, "project.project_types", []);
      const projectType = projectTypes.electricalFaultStudy();

      if (projectType) {
        return (
          project_types.length >= 1 &&
          !!_.find(project_types, item => item === projectType.value)
        );
      }

      return false;
    },

    is_only_electrical: state => {
      const project_types = _.get(state, "project.project_types", []);
      const esType = projectTypes.electricalStamp();
      const efsType = projectTypes.electricalFaultStudy();

      return (
        project_types.length <= 2 &&
        _.every(
          project_types,
          item => item === esType.value || item === efsType.value
        )
      );
    },

    is_full_structural: state => {
      const project_types = _.get(state, "project.project_types", []);
      const projectType = projectTypes.fullStructural();

      if (projectType) {
        return (
          project_types.length >= 1 &&
          !!_.find(project_types, item => item === projectType.value)
        );
      }

      return false;
    }
  },
  mutations: {
    SET_PROJECT_CREATED_AT: (state, payload) =>
      (state.project_created_at = payload),
    SET_PROJECT: (state, payload) => {
      // check for discrepancy,
      // note: remove block if addressed in BE
      // by adjusting `project_types` accordingly (for iebc_sp projects)
      const iebc_sp_id = 4;
      const is_there_iebc_sp = payload.project_types.some(
        type => type === iebc_sp_id
      );
      if (payload.project_type.value === iebc_sp_id && !is_there_iebc_sp) {
        payload.project_types.splice(0, 1, iebc_sp_id);
      }
      // remove block if addressed in BE
      state.project = payload;
    },
    SET_ENGINEERING_VALUES: (state, payload) =>
      (state.project.engineering_values = payload),
    SET_TRACKING: (state, payload) => {
      state.project.shipping_profile.tracking_number = payload.tracking_number;
      state.project.shipping_profile.tracking_url = payload.tracking_url;
    },
    SET_IS_WITHIN_TIME_DELAY: (state, payload) =>
      (state.is_within_time_delay = payload),
    SET_IS_STRUCTURAL_PLAN_STAMPED: (state, payload) =>
      (state.is_structural_plan_stamped = payload),
    SET_STATES: (state, payload) => (state.states = payload),
    SET_RECENTLY_UPLOADED_FILES: (state, payload) =>
      (state.recently_uploaded_files = payload)
  },
  actions: {
    getStates: async ({ commit }) => {
      const result = await api.get("/providers/states");
      const data = _.get(result, "data.result", []);
      commit("SET_STATES", data);

      return data;
    },
    getData: async ({ commit }, { id }) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get(`/projects/${id}`);
        const project = _.get(response, "data.result");

        commit("SET_PROJECT", project);

        return response;
      } catch (error) {
        throw error;
      }
    }
  }
};
